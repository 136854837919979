import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const FilterDrawerTrigger = ({ title, open, count, isDesktop, disabled, handleClick }) => {
  const withCount = typeof count !== 'undefined';
  const buttonRef = useRef();

  const { theme } = useSelector((state) => state.filterControls);

  return (
    <button
      ref={buttonRef}
      type="button"
      onClick={(e) => handleClick(e.target)}
      disabled={disabled}
      className="drv-filter-drawer-trigger"
      data-action="ga-filters#push"
      data-ga-filters-event-action={open ? 'closed_filter_category' : 'expanded_filter_category'}
      data-ga-filters-filter-name={title.toLowerCase().replaceAll(' ', '_')}
    >
      {title}
      {title === 'Initial payment' && <span className="drv-filter-drawer-trigger__info"> (equal to)</span>}
      {!open && withCount && !isDesktop && (
        <>
          {count !== 'Not selected' && (
            <svg
              className="drv-filter-drawer-trigger__check"
              width="18"
              height="18"
              fill={theme === 'electric' ? '#002b49' : '#ac8c30'}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.465 15.703a.83.83 0 001.156 0l9.188-9.187a.83.83 0 000-1.157l-1.125-1.125a.792.792 0 00-1.125 0l-7.5 7.5-3.531-3.5a.792.792 0 00-1.125 0L1.278 9.36a.83.83 0 000 1.157l5.187 5.187z"
                fill="#000"
              />
              <path d="M6.465 15.703a.83.83 0 001.156 0l9.188-9.187a.83.83 0 000-1.157l-1.125-1.125a.792.792 0 00-1.125 0l-7.5 7.5-3.531-3.5a.792.792 0 00-1.125 0L1.278 9.36a.83.83 0 000 1.157l5.187 5.187z" />
            </svg>
          )}
          <span className="drv-filter-drawer-trigger__count">({count})</span>
        </>
      )}
    </button>
  );
};

FilterDrawerTrigger.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDesktop: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

FilterDrawerTrigger.defaultProps = {
  open: false,
  count: undefined,
  isDesktop: false,
  disabled: false,
};

export default FilterDrawerTrigger;
